import { useEffect, useState, useCallback } from "react"
import { useQuery } from "@apollo/client"
import { AdminRedisScanQueryVariables, AdminRedisScanQuery } from "../../../__generated__/AdminRedisScanQuery"
import { ADMIN_REDIS_SCAN_QUERY } from "../../Admin/queries"

export const useRedisScan = function () {
  const [pattern, setPattern] = useState<string | undefined>(undefined)
  const { data, loading, fetchMore } = useQuery<AdminRedisScanQuery, AdminRedisScanQueryVariables>(ADMIN_REDIS_SCAN_QUERY)

  const cursor = data?.redisScan?.cursor ?? "0"

  const fetchMoreHelper = useCallback(
    (cursor?: string, discardPreviousResults = false) => {
      fetchMore({
        variables: { cursor, pattern },
        updateQuery: (previousResult, { fetchMoreResult, variables }) => {
          const newCursor = fetchMoreResult?.redisScan?.cursor ?? "0"
          const hasNext = fetchMoreResult?.redisScan?.hasNext ?? false

          let keys: Array<string> = []
          if (!discardPreviousResults) {
            const previousKeys = previousResult.redisScan?.keys ?? []
            keys = [...previousKeys]
          }
          const moreKeys = fetchMoreResult?.redisScan?.keys ?? []
          keys = [...keys, ...moreKeys]

          return {
            redisScan: {
              keys,
              hasNext,
              cursor: newCursor,
              __typename: "RedisScanObjectType",
            },
          }
        },
      })
    },
    [pattern, fetchMore],
  )

  const next = useCallback(() => {
    fetchMoreHelper(cursor)
  }, [cursor, fetchMoreHelper])

  const filter = useCallback((newPattern: string) => {
    setPattern(newPattern?.trim() || undefined)
  }, [])

  useEffect(() => {
    fetchMoreHelper("0", true)
  }, [fetchMoreHelper])

  const keyData = {
    keys: data?.redisScan?.keys ?? [],
    loading,
    filter,
  }

  const paginationData = {
    hasNext: data?.redisScan?.hasNext ?? false,
    next,
  }

  return { data: keyData, pagination: paginationData }
}
