import React, { FC, useMemo, useCallback } from "react"
import { useForm } from "react-hook-form"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { useQuery, useMutation } from "@apollo/client"
import { TextField } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import { AdminRedisKeyQuery, AdminRedisKeyQueryVariables } from "../../../__generated__/AdminRedisKeyQuery"
import { AdminRedisSetKeyMutation, AdminRedisSetKeyMutationVariables } from "../../../__generated__/AdminRedisSetKeyMutation"
import { AdminRedisUnlinkKeyMutation, AdminRedisUnlinkKeyMutationVariables } from "../../../__generated__/AdminRedisUnlinkKeyMutation"
import { AdminRedisScanQuery, AdminRedisScanQueryVariables } from "../../../__generated__/AdminRedisScanQuery"
import LoadingView from "../components/LoadingView"
import { ADMIN_REDIS_KEY_QUERY, ADMIN_REDIS_SCAN_QUERY, ADMIN_REDIS_SET_KEY_MUTATION, ADMIN_REDIS_UNLINK_KEY_MUTATION } from "../queries"

interface IProps {
  redisKey: string
}

interface IFormProps {
  value: string
  ttl: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    button: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      "&:last-of-type": {
        marginRight: 0,
      },
    },
  }),
)

const RedisItem: FC<IProps> = ({ redisKey }) => {
  const classes = useStyles()
  const { register, handleSubmit } = useForm<IFormProps>()
  const { data, loading } = useQuery<AdminRedisKeyQuery, AdminRedisKeyQueryVariables>(ADMIN_REDIS_KEY_QUERY, { variables: { key: redisKey } })
  const [updateItem, { loading: isUpdating }] = useMutation<AdminRedisSetKeyMutation, AdminRedisSetKeyMutationVariables>(ADMIN_REDIS_SET_KEY_MUTATION)
  const [unlinkItem, { loading: isUnlinking }] = useMutation<AdminRedisUnlinkKeyMutation, AdminRedisUnlinkKeyMutationVariables>(
    ADMIN_REDIS_UNLINK_KEY_MUTATION,
    {
      variables: {
        key: redisKey,
      },
      update: (store, { data: mutationResult }) => {
        const currentContent = store.readQuery<AdminRedisScanQuery, AdminRedisScanQueryVariables>({ query: ADMIN_REDIS_SCAN_QUERY })
        const currentKeys = currentContent?.redisScan?.keys ?? []
        const newKeys = currentKeys.filter((key) => key !== mutationResult?.unlinkRedisKey)

        const newData = {
          redisScan: {
            ...(currentContent?.redisScan ?? {}),
            keys: newKeys,
          },
        }

        store.writeQuery({ query: ADMIN_REDIS_SCAN_QUERY, data: newData })
      },
    },
  )

  const currentValue = data?.redisKey?.value ?? ""
  const currentTTL = data?.redisKey?.ttl ?? -1

  const parsedValue = useMemo(() => {
    try {
      return JSON.stringify(JSON.parse(currentValue), undefined, 4)
    } catch (err) {
      return currentValue
    }
  }, [currentValue])

  const handleUnlink = useCallback(() => {
    unlinkItem()
  }, [unlinkItem])

  const handleUpdate = ({ ttl, value }: IFormProps) => {
    const parsedTTL = isNaN(Number(ttl)) ? -1 : Number(ttl)
    updateItem({
      variables: {
        value,
        ttl: parsedTTL,
        key: redisKey,
      },
    })
  }

  if (loading) {
    return <LoadingView />
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit(handleUpdate)}>
      <TextField name="ttl" inputRef={register} variant="outlined" label="TTL (seconds)" type="number" fullWidth defaultValue={currentTTL} />
      <TextField name="value" inputRef={register} variant="outlined" label="Value" multiline fullWidth rowsMax={10} defaultValue={parsedValue} />
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.button} type="button" variant="outlined" onClick={handleUnlink} disabled={isUnlinking}>
          Delete
        </Button>
        <Button className={classes.button} type="submit" variant="outlined" color="primary" disabled={isUpdating}>
          Update
        </Button>
      </Box>
    </form>
  )
}

export default RedisItem
