import React, { useState, useCallback, ChangeEvent } from "react"
import debounce from "debounce"
import { useMutation } from "@apollo/client"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import TableRow from "@material-ui/core/TableRow"
import SearchIcon from "@material-ui/icons/Search"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import TableFooter from "@material-ui/core/TableFooter"
import TableContainer from "@material-ui/core/TableContainer"
import InputAdornment from "@material-ui/core/InputAdornment"
import DeleteIcon from "@material-ui/icons/DeleteSweep"
import { AdminRedisFlushDb } from "../../../__generated__/AdminRedisFlushDb"
import { ADMIN_REDIS_FLUSH_DB, ADMIN_REDIS_SCAN_QUERY } from "../queries"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { useRedisScan } from "./useRedisScan"
import RedisKeyRow from "./RedisKeyRow"

const NO_DATA_MESSAGE = "0 Redis keys found!"
const CONFIRMATION_MESSAGE = "DELETE ALL KEYS"
const EMPTY_SCAN_DATA = {
  redisScan: {
    keys: [],
    hasNext: false,
    cursor: "0",
    __typename: "RedisScanObjectType",
  },
}

const useCacheManagerStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textTransform: "uppercase",
      marginRight: "auto",
    },
    clearRedisInput: {
      minWidth: 360,
      marginLeft: theme.spacing(3),
    },
    tableToolbar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  }),
)

const CacheManager = () => {
  const classes = useCacheManagerStyles()
  const [confirm, setConfirm] = useState("")
  const [pattern, setPattern] = useState("")
  const [flushDb, { loading: isFlushing }] = useMutation<AdminRedisFlushDb>(ADMIN_REDIS_FLUSH_DB, {
    update: (store) => {
      store.writeQuery({ query: ADMIN_REDIS_SCAN_QUERY, data: EMPTY_SCAN_DATA })
    },
  })

  const {
    data: { keys, loading, filter },
    pagination: { hasNext, next },
  } = useRedisScan()

  const handleFlushDb = useCallback(() => {
    flushDb()
  }, [flushDb])

  const handleFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPattern(e.target.value)
  }, [])

  const handleConfirmChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setConfirm(e.target.value)
  }, [])

  const debouncedApplyFilter = React.useMemo(() => debounce((pattern: string) => filter(pattern), 500), [filter])

  React.useEffect(() => {
    debouncedApplyFilter(pattern)
  }, [pattern, debouncedApplyFilter])

  if (loading) {
    return <LoadingView />
  }

  const tableBody =
    keys.length > 0 ? (
      keys.map((key: string) => <RedisKeyRow key={key} redisKey={key} />)
    ) : (
      <TableRow>
        <TableCell colSpan={2}>{NO_DATA_MESSAGE}</TableCell>
      </TableRow>
    )

  return (
    <Paper>
      <Toolbar className={classes.tableToolbar}>
        <Typography className={classes.title} variant="subtitle1">
          Redis Keys
        </Typography>
        <TextField
          placeholder="Match pattern"
          type="text"
          value={pattern}
          onChange={handleFilterChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={classes.clearRedisInput}
          type="text"
          placeholder={`Type ${CONFIRMATION_MESSAGE} to enable`}
          value={confirm}
          onChange={handleConfirmChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled={CONFIRMATION_MESSAGE !== confirm || isFlushing} title="Deletes all keys from Redis" onClick={handleFlushDb}>
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <TableContainer>
        <Table size="small">
          <TableBody>{tableBody}</TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <Button onClick={next} disabled={!hasNext} fullWidth>
                  Load More
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default () => (
  <Layout title="Cache Manager">
    <CacheManager></CacheManager>
  </Layout>
)
