import React, { useState, useCallback, Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import TableRow from "@material-ui/core/TableRow"
import Collapse from "@material-ui/core/Collapse"
import TableCell from "@material-ui/core/TableCell"
import IconButton from "@material-ui/core/IconButton"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import RedisItem from "./redisItem"

const useRowStyles = makeStyles({
  root: {
    cursor: "pointer",
    "& > *": {
      borderBottom: "unset",
    },
  },
  noPaddingCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})

const RedisKeyRow = (props) => {
  const { redisKey } = props
  const [open, setOpen] = useState(false)
  const classes = useRowStyles()

  const toggle = useCallback(() => setOpen(!open), [open])

  return (
    <Fragment>
      <TableRow className={classes.root} onClick={toggle}>
        <TableCell width={40}>
          <IconButton aria-label="expand row" size="small" onClick={toggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {redisKey}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.noPaddingCell} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <RedisItem redisKey={redisKey} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default RedisKeyRow
